import React,{useEffect} from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardContainer from './components/MainContainer'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';


import { DealerProvider } from "./components/shared/contexts/DealerContext";
import { MakeProvider } from "./components/shared/contexts/MakeContext";
import { CompareProvider } from './components/shared/contexts/CompareContext';
import { FavouriteProvider } from './components/shared/contexts/FavouriteContext';
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
import SidePopUp from './components/SidePopUp/SidePopUp';
import { useHistory } from "react-router-dom";

function App() {
    const parsedData = window.location.pathname.split("/");
    var domain = "/"

    if (parsedData.length > 1 && parsedData[1] == "dealer") {
        domain = "/dealer/" + parsedData[2];
    }


    return (
        <Router basename={domain}>
        
            <FavouriteProvider>
                <CompareProvider>
                    <ThemeProvider >
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                            <CssBaseline />
                            <VehicleProvider>
                            <DealerProvider>
                                <MakeProvider>
                                    <DashboardContainer />
                                </MakeProvider>
                                <SidePopUp/>
                            </DealerProvider>
                            </VehicleProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </CompareProvider>
            </FavouriteProvider>
        </Router>
    );
}

export default App;
