import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";

import { VehicleContext } from "../shared/contexts/VehicleContext";
import { UsedVehiclesFilter } from "@red-build/leadcentre";
import { BASE_URI, DEALERID } from "../shared/Constants";

import { useHistory, useParams } from "react-router-dom";
import { padding } from "@mui/system";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "50px 15px",
    display: "flex",
    flexDirection:'column',
    
    "@media (max-width: 768px)": {},
  },
  innerHolderFilter: {
    maxWidth: "1770px",
  width:'100%',
    margin: " 0 auto",
  },
  innerHolder: {
    maxWidth: "1770px",
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    width: "100%",
  },
  card: {
    width: "calc(100% / 4 - 20px )",
    minWidth: "calc(100% / 4 - 20px )",
    cursor:'pointer',
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    margin: "10px 10px",

    "@media (max-width: 425px)": {
      width: "unset",
    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      width: "calc(100% / 2 - 20px )",
    },
  },
  cardTextHolder: {
    padding: "20px",
    background:'#b81b22',
    color:'white',
    "@media (max-width: 768px)": {},
  },
  cardTextHolderGroup:{
    display:'flex',
    justifyContent:'space-between',
    margin:'5px 0px'
  },
  cta:{
    border:"none",
    margin:'0 auto',
    display:'block',
    padding:'10px 40px',
    background: '#b81b22',
    color:'white',
    cursor:'pointer'
  },
  cardTextTitleHolder:{
 
    marginBottom:'10px'
  },
  cardTextTitle:{
    fontSize:'19px',
    fontWeight:'500',

  },
  imageHolder:{

  }
}));

const ShowRoom = () => {
  const history = useHistory();

  const classes = useStyles();
  const { newVehiclesList, setVehiclesList } = useContext(VehicleContext);

  const [vehicles, setVehicles] = useState(newVehiclesList);

  const [filter, setFilter] = useState(false);

  let vehicle = history.location.state;

  useEffect(() => {
    setVehicles(newVehiclesList);
    console.log(newVehiclesList);
  }, [newVehiclesList]);

  const handleRedirect = (data) => {


    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`,data)
     };

  const defaultColors = {
    primaryColor: "black",
    secondaryColor: "#b81b22",
    labelColor:'black',
  };

  const multiSelect = true;

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });
  
  return (
    <>
  
 
    <div className={classes.root}>
      {/* <div className={classes.innerHolderFilter}> 
    <UsedVehiclesFilter
    pageSize={20}
    dealerId={76}
    motorgroupId={7}
    orientation='row'
    updateFilteredVehicles={setVehicles}
    vehicles={brandedList}
    base_uri={BASE_URI}
    websiteColors={defaultColors}
    multiSelect={multiSelect}
  />
  </div> */}
      <div className={classes.innerHolder}>
        {vehicles.flat().map((v, index) => {
          return (
            <div
              className={classes.card}
              onClick={() => {
                handleRedirect(v);
              }}
            >
                         <div className={classes.imageHolder}>
              <img src={v?.image} width="100%" />
   
              </div>
              <div className={classes.cardTextHolder}>
              
              <div className={classes.cardTextTitleHolder}>
               
                <div className={classes.cardTextTitle}>{v?.title}</div>
                <div className={classes.cardTextTitle}>{formatter.format(v.price)}</div>
                </div>
              </div>
            
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default ShowRoom;
