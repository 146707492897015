import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URI,MASTER_CONFIGURATOR_DEALERID,DEALERID,MOTORGROUPID} from '../Constants.js'

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {

    const [vehiclesList, setVehiclesList] = useState([]);
    const [brandedList, setBrandedList] = useState([]);
    const [newVehiclesList, setNewVehiclesList] = useState([]);

    let dealerList = [432,431]
    useEffect(() => {

        let arr = []
            const params = {
                pageNumber: 1,
                pageSize: 1000,
                motorGroupId:MOTORGROUPID
            }
            
            // if (globalDealer?.id != DEALERID) {
            //     params.dealerId = globalDealer.id;
            // } else {
            //     params.motorGroupId = MOTORGROUPID;
            // }
            
            axios({
                method: 'GET',
                url: `${BASE_URI}/stock`,
                params,
             
            }).then((response) => {

            let data = response?.data?.list?.filter((v)=>(v?.dealerId == 431) || (v?.dealerId == 432)).filter((v)=> v.brand == "CHERY")
                
                setVehiclesList(removeDuplicates(data,it =>it.stockNo));

                function removeDuplicates(data,key){
                    return[
                        ...new Map(
                            data.map(x => [key(x),x])
                        ).values()
                    ]
                }
               
                setBrandedList(removeDuplicates(response.data?.list?.filter((v)=> v.brand == "CHERY"),it =>it));
        
            }).catch(error => {
                if (axios.isCancel(error)) return
                
            })
    
        
    }, [])
    
    useEffect(() => {

        const params = {
            dealerId:MASTER_CONFIGURATOR_DEALERID,
            pageNumber: 1,
            pageSize: 1000,
          
         
            pageNumber:1,
            pageSize:20000
        }
        
        // if (globalDealer?.id != DEALERID) {
        //     params.dealerId = globalDealer.id;
        // } else {
        //     params.motorGroupId = MOTORGROUPID;
        // }
        
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealermodels`,
            params,
  
        }).then((response) => {
            
            setNewVehiclesList(response.data?.list.filter((v)=> v.makeId == 8104));
    
    
        }).catch(error => {
            if (axios.isCancel(error)) return
            
        })

    
}, [])

    return (
        <VehicleContext.Provider value={{ vehiclesList, setVehiclesList,brandedList,newVehiclesList }}>
            {children}
        </VehicleContext.Provider>
    )
};